function App() {
  return (
    <article>
      <h1>We&rsquo;ll be back soon!</h1>
      <div>
        <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to you can always <a href="mailto:info@wiasoft.com">contact us</a>, otherwise we&rsquo;ll be back online shortly!</p>
        <a href="https:wiasoft.com"><p>&mdash; WiaSoft Team 2024</p></a>
      </div>
    </article>
  );
}

export default App;
